import { DragEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  CloseImageEditor,
  ColorPickerTool,
  CropTool,
  HandTool,
  MoveTool,
  PenTool,
  RectTool,
  RedoTool,
  ResetTool,
  UndoTool,
  UploadIcon,
} from "../../svg";
import IconBtn from "../../form/icon-btn";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { v4 as uuidv4 } from "uuid";
import deteleIcon from "../../../assets/images/editorDeteleIcon.svg";
import uploadFile from "../../../services/uploadFile";
import { useTranslation } from "react-i18next";

declare module "fabric" {
  interface Canvas {
    __eventListeners: any;
  }
}
declare module "fabric" {
  namespace fabric {
    interface Object {
      uniqueId?: any;
      isDeleted?: any;
    }
  }
}
function ImageEditor({
  // data,
  // defaultUniqueId,
  // onObjectDataChange,
  // error,
  imageSource
}: any) {
  const generateGuid = () => uuidv4();
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const uploadFiles = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<boolean>(false);
  const { editor, onReady, selectedObjects } = useFabricJSEditor();
  const [color, setColor] = useState("#FCE049");
  const [strokeLevel, setStrokeLevel] = useState<Number>(10);
  const [cropImage, setCropImage] = useState<boolean>(false);
  const [rectImage, setRectImage] = useState<boolean>(false);
  const [drawImage, setDrawImage] = useState<boolean>(false);
  const [moveImage, setMoveImage] = useState<boolean>(false);
  const [currentTool, setCurrentTool] = useState<string>("");
  const [imgSrc, setImgSrc] = useState<string>("");
  const history: any[] = [];

  const renderImage = (base64URL: string) => {
    if (!editor || !fabric) {
      return;
    }
    setImgSrc(base64URL);
    fabric.Image.fromURL(base64URL, function (img) {
      editor.canvas.setBackgroundImage(
        img,
        editor.canvas.renderAll.bind(editor.canvas)
      );
      if (Number(img.width) >= 100 || Number(img.height) >= 100) {
        const zoomLevel = Math.min(
          Number(editor.canvas.width) / Number(img.width),
          Number(editor.canvas.height) / Number(img.height)
        );
        const centerX = Number(editor.canvas.width) / 20;
        const centerY = Number(editor.canvas.height) / 20;
        editor.canvas.zoomToPoint({ x: centerX, y: centerY }, zoomLevel);
      } else {
        editor.canvas.zoomToPoint({ x: 1, y: 1 }, 1.4);
      }
    });
  };

  useEffect(() => {
    if (!editor || !fabric) {
      console.log("renderImage: editor or fabric is not defined");
      return;
    }
    if (imageSource) {
      setSelectedImage(true);
      renderImage(imageSource.url);
    } else {
      setSelectedImage(false);
    }
  }, [imageSource, editor, fabric]);

  // canvas
  const toggleCrop = () => {
    setCropImage(!cropImage);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
  };

  const toggleRect = () => {
    setRectImage(true);
    setCropImage(false);
    setDrawImage(false);
    setMoveImage(false);
  };

  const toggleDraw = () => {
    if (!editor || !fabric) {
      return;
    }
    if (
      !editor ||
      !editor.canvas.width ||
      !editor.canvas.height ||
      !editor.canvas.backgroundImage
    ) {
      return;
    }
    editor.canvas.freeDrawingBrush.width = Number(strokeLevel);
    editor.canvas.freeDrawingBrush.color = color;
    editor.canvas.isDrawingMode = true;
    editor.canvas.on("path:created", (event) => {
      const e = event as fabric.IEvent<MouseEvent> & { path: fabric.Path };
      e.path.uniqueId = uuidv4();
    });
    setDrawImage(true);
    setRectImage(false);
    setCropImage(false);
    setMoveImage(false);
  };

  const toggleMove = () => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.isDrawingMode = false;
    setMoveImage(true);
    setDrawImage(false);
    setRectImage(false);
    setCropImage(false);
  };

  const undo = () => {
    if (!editor || !fabric) {
      return;
    }
    if (editor.canvas._objects.length > 0) {
      history.push(editor.canvas._objects.pop());
    }
    editor.canvas.renderAll();
    editor.canvas.discardActiveObject().renderAll();
    setCropImage(false);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
    editor.canvas.isDrawingMode = false;
  };

  const resetAll = () => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas._objects.splice(0, editor.canvas._objects.length);
    history.splice(0, history.length);
    editor.canvas.renderAll();
    setCropImage(false);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
    editor.canvas.isDrawingMode = false;
    editor.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
  }, [editor?.canvas.isDrawingMode]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.freeDrawingBrush.color = color;
    editor.setStrokeColor(color);
  }, [color]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.on("mouse:wheel", handleMouseWheel);
    if (cropImage) {
      editor.canvas.on("mouse:down", handleMouseDown);
      editor.canvas.on("mouse:move", handleMouseMove);
      editor.canvas.on("mouse:up", handleMouseUp);
      editor.canvas.isDrawingMode = false;
      setRectImage(false);
      setDrawImage(false);
      setMoveImage(false);
    }
    editor.canvas.renderAll();
    return () => {
      editor.canvas.off("mouse:wheel", handleMouseWheel);
      editor.canvas.off("mouse:down", handleMouseDown);
      editor.canvas.off("mouse:move", handleMouseMove);
      editor.canvas.off("mouse:up", handleMouseUp);
    };
  }, [editor, cropImage]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.on("after:render", handleOverAllMouseUp);
    return () => {
      editor.canvas.off("after:render", handleOverAllMouseUp);
    };
  });

  const handleOverAllMouseUp = () => {
    getAllObjectData();
  };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    let rectangle: fabric.Object, isDown: boolean, origX: number, origY: number;
    if (rectImage) {
      editor.canvas.on("mouse:down", function (o) {
        if (o.target == null) {
          editor.canvas.isDrawingMode = false;
          var pointer = editor.canvas.getPointer(o.e);
          isDown = true;
          origX = pointer.x;
          origY = pointer.y;
          rectangle = new fabric.Rect({
            left: origX,
            top: origY,
            fill: "transparent",
            stroke: color,
            strokeWidth: Number(strokeLevel),
            width: 0,
            height: 0,
          });
          (rectangle as any).uniqueId = generateGuid();
          editor.canvas.add(rectangle);
        }
      });
      editor.canvas.on("mouse:move", function (o) {
        editor.canvas.isDrawingMode = false;
        if (!isDown) return;

        var pointer = editor.canvas.getPointer(o.e);
        if (origX > pointer.x) {
          rectangle.set({ left: Math.abs(pointer.x) });
        }
        if (origY > pointer.y) {
          rectangle.set({ top: Math.abs(pointer.y) });
        }
        rectangle.set({ width: Math.abs(origX - pointer.x) });
        rectangle.set({ height: Math.abs(origY - pointer.y) });
        editor.canvas.renderAll();
      });
      editor.canvas.on("mouse:up", function (o) {
        editor.canvas.isDrawingMode = false;
        isDown = false;
        if (o.target) {
          if (o.target.width === 0) {
            editor.canvas.remove(o.target);
            editor.canvas.renderAll();
          }
        }
        // if (o.target) {
        //   if (o.target.width === 0) {
        //     editor.canvas.remove(o.target);
        //     editor.canvas.renderAll();
        //   }else{
        //     hideForm((rectangle as any).uniqueId);
        //   }
        // }else{
        //   showForm((rectangle as any).uniqueId);
        // }
      });
    }
    return () => {
      editor.canvas.isDrawingMode = false;
      editor.canvas.off("mouse:down");
      editor.canvas.off("mouse:move");
      editor.canvas.off("mouse:up");
      editor.canvas.off("mouse:over");
    };
  }, [editor, rectImage, strokeLevel, color]);

// const showForm = (uniqueId: string) => {
//   console.log(`Show form for rectangle: ${uniqueId}`);
// };

// const hideForm = (uniqueId: string) => {
//   console.log(`Hide form for rectangle: ${uniqueId}`);
// };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    if (
      !editor ||
      !editor.canvas.width ||
      !editor.canvas.height ||
      !editor.canvas.backgroundImage
    ) {
      return;
    }
    editor.canvas.freeDrawingBrush.width = Number(strokeLevel);
    const obj: any = editor.canvas.getActiveObjects();
    if (obj) obj[0]?.set({ strokeWidth: strokeLevel });
    editor.canvas.isDrawingMode = true;
  }, [editor, strokeLevel]);

  useEffect(() => {
    const img = new Image();
    img.src = deteleIcon;
    function deleteObject(eventData: MouseEvent, transform: any): boolean {
      const target = transform.target;
      const canvas = target.canvas;
      if (canvas) {
        canvas.remove(target);
        canvas.requestRenderAll();
        return true;
      }
      return false;
    }
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 0,
      offsetX: 20,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: (ctx, left, top, styleOverride, fabricObject: any) => {
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -20 / 2, -20 / 2, 20, 20);
        ctx.restore();
      },
    });
  }, [editor]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    fabric.Object.prototype.set({
      transparentCorners: false,
      cornerColor: "#0078d4",
      cornerStrokeColor: "white",
      borderColor: "#0078d4",
      cornerSize: 12,
      borderOpacityWhenMoving: 0.5,
      padding: 10,
      cornerStyle: "circle",
      borderDashArray: [5, 5],
      uniqueId: generateGuid(),
      isDeleted: 0,
    });
  }, [editor]);

  useEffect(() => {
    if (cropImage) setCurrentTool("crop");
    if (rectImage) setCurrentTool("rect");
    if (drawImage) setCurrentTool("draw");
    if (moveImage) setCurrentTool("move");

    if (!cropImage && !rectImage && !drawImage && !moveImage)
      setCurrentTool(" ");
    if (!editor) {
      return;
    }
    if (!cropImage) {
      if (drawImage) {
        editor.canvas.isDrawingMode = true;
      }
    }
  }, [cropImage, rectImage, drawImage, moveImage]);

  const renderSidebar = useMemo(() => {
    if (selectedImage) {
      if (rectImage || drawImage) {
        return (
          <div
            className={`sidebar ${
              selectedImage
                ? "visible pointer-events-auto"
                : " invisible pointer-events-none"
            }`}
          >
            <input
              type="range"
              onChange={(e: any) => setStrokeLevel(e.target.value)}
              value={strokeLevel.toString()}
              min={1}
              max={100}
              id="strokelevel"
              title="strokeSize"
            />
            <label
              className="font-bold rotate-90 translate-x-3"
              htmlFor="strokelevel"
            >
              {strokeLevel.toString()}
            </label>
          </div>
        );
      }
    }
  }, [rectImage, selectedImage, strokeLevel, drawImage]);

  function handleMouseWheel(event: any) {
    if (!editor || !fabric) {
      return;
    }
    let delta = event.e.deltaY;
    let zoom = editor.canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    editor.canvas.zoomToPoint({ x: event.e.offsetX, y: event.e.offsetY }, zoom);
    event.e.preventDefault();
    event.e.stopPropagation();
  }
  function handleMouseDown(this: any, opt: any) {
    var evt = opt.e;
    (this as any).isDragging = true;
    (this as any).selection = false;
    (this as any).lastPosX = evt.clientX;
    (this as any).lastPosY = evt.clientY;
  }
  function handleMouseMove(this: any, opt: any) {
    if ((this as any).isDragging) {
      var e = opt.e;
      var vpt = (this as any).viewportTransform;
      vpt[4] += e.clientX - (this as any).lastPosX;
      vpt[5] += e.clientY - (this as any).lastPosY;
      (this as any).requestRenderAll();
      (this as any).lastPosX = e.clientX;
      (this as any).lastPosY = e.clientY;
    }
  }
  function handleMouseUp(this: any, opt: any) {
    (this as any).setViewportTransform((this as any).viewportTransform);
    (this as any).isDragging = false;
    (this as any).selection = true;
  }

  const resetAllHandle = () => {
    if (!editor || !fabric) {
      return;
    }
    resetAll();
    setCurrentTool("");
    setColor("#FCE049");
    setStrokeLevel(10);
    setCropImage(false);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
    setSelectedImage(false);
    editor.canvas.isDrawingMode = false;
    // currentLabel.magicUrl = "";

    if (uploadFiles.current) {
      uploadFiles.current.value = "";
    }
    editor.canvas.renderAndReset();
    editor.deleteAll();
    editor.canvas.remove();
    editor.canvas.clear();
    editor.canvas.discardActiveObject();
    editor.canvas.renderAll();
    editor.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };

  const pickColor = (e: any) => {
    setColor(e.target.value);
  };

  const getAllObjectData = () => {
    if (!editor || !fabric) {
      return [];
    }
    const objects = editor.canvas.getObjects();
    const objectData = objects.map((object, i) => {
      let data: any;
      if (object.type === "path" && object instanceof fabric.Path) {
        data = {
          annotationId: i,
          uniqueId: object.uniqueId,
          isDeleted: object.isDeleted,
          type: object.type,
          color: object.stroke,
          coordinates: [object.path],
        };
      } else {
        data = {
          annotationId: i,
          uniqueId: object.uniqueId,
          isDeleted: object.isDeleted,
          type: object.type,
          color: object.stroke,
          coordinates: [
            {
              left: object.left,
              top: object.top,
              width: object.width,
              height: object.height,
              fill: object.fill,
              stroke: object.stroke,
              strokeWidth: object.strokeWidth,
              type: object.type,
              color: object.stroke,
            },
          ],
        };
      }
      return data;
    });
    return objectData;
  };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
  
    const createFabricObjects = (annotationDetails:any) => {
      if (!fabric || !annotationDetails) {
        return [];
      }
      const objects = annotationDetails.map((annotation:any) => {
        let fabricObject;
  
        switch (annotation.type) {
          case 1: // Rectangle
            if (Array.isArray(annotation.coordinates) && annotation.coordinates.length === 2) {
              const [start, end] = annotation.coordinates;
              fabricObject = new fabric.Rect({
                left: start.x,
                top: start.y,
                width: end.x - start.x,
                height: end.y - start.y,
                fill: "transparent",
                // stroke: annotation.hexaColor,
                stroke: "#ffff00",
                strokeWidth: 5,
                selectable: false
              });
            }
            break;
  
          case 2: // Circle
            if (Array.isArray(annotation.coordinates) && annotation.coordinates.length === 2) {
              const [center, edge] = annotation.coordinates;
              const radius = Math.sqrt(
                Math.pow(edge.x - center.x, 2) + Math.pow(edge.y - center.y, 2)
              );
              fabricObject = new fabric.Circle({
                left: center.x - radius,
                top: center.y - radius,
                radius,
                fill:"transparent",
                // stroke: annotation.hexaColor,
                stroke: "#ffff00",
                strokeWidth: 5,
                selectable: false
              });
            }
            break;
  
          case 3: // Free flow (Path)
            if (Array.isArray(annotation.coordinates)) {
              const pathData = annotation.coordinates.map((point:any, index:any) =>
                index === 0 ? `M ${point.x} ${point.y}` : `L ${point.x} ${point.y}`
              ).join(" ");
              fabricObject = new fabric.Path(pathData, {
                fill: "transparent",
                 // stroke: annotation.hexaColor,
                 stroke: "#ffff00",
                strokeWidth: 5,
                selectable: false,
              });
            }
            break;

            case 4: // Cross Mark
              if (Array.isArray(annotation.coordinates) && annotation.coordinates.length === 2) {
                  const [start, end] = annotation.coordinates;
                  // Create two lines for the "X" mark
                  const line1 = new fabric.Line([start.x, start.y, end.x, end.y], {
                      stroke: "#ffff00",
                      strokeWidth: 5,
                      selectable: false,
                  });
                  const line2 = new fabric.Line([start.x, end.y, end.x, start.y], {
                      stroke: "#ffff00",
                      strokeWidth: 5,
                      selectable: false,
                  });
                  // Group the lines into a single object for better management
                  fabricObject = new fabric.Group([line1, line2], {
                      selectable: false,
                  });
              }
            break;
  
          default:
            console.warn(`Unsupported annotation type: ${annotation.type}`);
        }
        return fabricObject;
      });
      // Filter out null or undefined objects
      return objects.filter((obj:any) => obj !== null && obj !== undefined);
    };
  
    const annotatedDetails = imageSource?.annotatedDetails;
    const fabricObjects = createFabricObjects(annotatedDetails);
  
    // Add the objects to the canvas
    if (editor && editor.canvas && fabricObjects.length > 0) {
      editor.canvas.clear();
      editor.canvas.add(...fabricObjects);
    }
  }, [editor, fabric]);

  const { t } = useTranslation();
  return (
    <div className="w-full h-full">
      {/*<div className="w-full h-auto p-6 bg-[#F4FAFF] dark:bg-io-black-1c mb-5">
        <div className="w-full h-auto flex justify-start items-center gap-[8%]">
           <IconBtn
            size="w-[1.8rem]"
            icon={<RectTool />}
            handleClick={() => toggleRect()}
            disabled={cropImage || !selectedImage}
            title={t("web.imageeditor.title.rectangle")}
            isActive={currentTool === "rect"}
          />
          <IconBtn
            size="w-[1.8rem]"
            icon={<MoveTool />}
            handleClick={() => toggleMove()}
            disabled={cropImage || !selectedImage}
            title={t("web.imageeditor.title.selection")}
            isActive={currentTool === "move"}
          />
          <IconBtn
            size="w-[1.8rem]"
            icon={<PenTool />}
            handleClick={() => toggleDraw()}
            disabled={cropImage || !selectedImage}
            title={t("web.imageeditor.title.draw")}
            isActive={currentTool === "draw"}
          /> 
          <IconBtn
            size="w-[2.5rem]"
            icon={<HandTool />}
            handleClick={() => toggleCrop()}
            disabled={!selectedImage}
            title={t("web.imageeditor.title.crop")}
            isActive={currentTool === "crop"}
          />
          <IconBtn
            size="w-[1.8rem]"
            icon={<UndoTool />}
            handleClick={() => undo()}
            disabled={cropImage || !selectedImage}
            title={t("web.imageeditor.title.undo")}
          />
          <IconBtn
            size="w-[1.8rem]"
            icon={<ResetTool />}
            handleClick={() => resetAll()}
            title={t("web.imageeditor.title.resetall")}
            disabled={cropImage || !selectedImage}
          /> 
          <label
            htmlFor="colorPicker"
            className="w-[2.5rem] h-[2.5rem] relative"
          >
            <input
              type="color"
              id="colorPicker"
              className="w-0 h-0 invisible"
              value={color}
              onChange={(e) => pickColor(e)}
            />
            <div
              title={t("web.imageeditor.title.colorpicker")}
              className="pointer-events-none absolute top-0 left-0">
              <IconBtn size="w-[2.5rem]" icon={<ColorPickerTool />} />
            </div>
          </label>
        </div>
      </div> */}
      <div className="w-full h-[80%] relative mb-[7rem]">
        <div className="w-full h-full">
        </div>
        <div
          className={`w-full h-full absolute top-0 left-0 ${
            selectedImage
              ? "visible pointer-events-auto"
              : " invisible pointer-events-none"
          }`}
        >
          <div className="w-full h-full relative bg-[#F4FAFF] dark:bg-io-black-15">
            <div className="w-full h-full rounded-[8px] overflow-hidden border-2 border-[#0078D4] ">
              <FabricJSCanvas className="w-full h-full" onReady={onReady} />
            </div>
            {/* {renderSidebar} */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ImageEditor;
