import { useEffect, useState } from "react";
import { InputField, TextAreaField } from "../../form/input-fields";
import ImageEditorNew from "../image-editor-new";
import viewIcon from "../../../assets/images/viewIcon.png"
import uploadFile from "../../../services/uploadFile";
import { v4 as uuidv4 } from "uuid";
import { processService } from "../../../services/InspectionOneServices";
import { useParams } from "react-router-dom";

function DamageImageDetails({
  answerDetails,
  setAnswerDetails,
  questionData,
  fullProcessData,
  selectedScreenId,
  isUploadType,
  setSelectedInspection,
  selectedInspection,
  setLoadingStates,
  loadingStates,
  getAnomalyDetection,
  uploadedPdfUrl,
  defaultInputDetails
}: any) {
  const { siteUniqueId, languageCode } = useParams();
  const generateGuid = () => uuidv4();
  const [isUploadLoading, setUploadIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenModalForm, setIsOpenModalForm] = useState(false);
  const [ishowForm, setIsShowForm] = useState(false);
 

  const openModal = (inspection: any) => {
    setSelectedInspection(inspection);
    setIsModalOpen(true);
    console.log("inspection",inspection)
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedInspection(undefined);
  };
  
  const handleImageUpload = async (event: any, key: any) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = async () => {
      setLoadingStates((prev:any) => ({ ...prev, [key.id]: true }));
      const fileName = file.name;
      const fileType = fileName.split(".").pop();
      const extension = fileType ? fileType.toLowerCase() : "";
      uploadFile(file, extension).then(({ response, fileUrl }) => {
        if (response.success) {
          const uploadedFileUrl = response.data.fileUrl;

          // Update answerDetails with the uploaded image
          setAnswerDetails((prevDetails: any) =>
            prevDetails.map((detail: any) =>
              detail.id === key.id
                ? {
                    ...detail,
                    url: uploadedFileUrl,
                    imageId: generateGuid(),
                  }
                : detail
            )
          );
          // Prepare JSON data for API call
          const anomalyDetectionData = {
            "tenantId": fullProcessData.appDetails.tenantId,
            "siteId": fullProcessData.appDetails.siteId,
            "siteUserId": fullProcessData.appDetails.siteUserId,
            "processId": fullProcessData.processId,
            "processLogicId": fullProcessData.processLogicId,
            "subProcessLogicId": fullProcessData.subProcessLogicId,
            "userName": "",
            "isRegisteredUser": 1,
            "labelValue": key.name,
            "surveyId": fullProcessData.screenDetail.surveyId,
            "screenId": selectedScreenId,
            "questionnaireUniqueId": questionData.id,
            "title": questionData.title,
            "uniqueId": key.id,
            "uniqueIndex": 0,
            "type": questionData.type,
            "subType": questionData.type,
            "name":key.name,
            "id":key.id,
            "url":uploadedFileUrl,
            dbUrl: `${process.env.REACT_APP_BASE_API_URL}/ProcessRegistryDamageDetectionAction`,
            apiKey: process.env.REACT_APP_WRAPPER_KEY,
            secretKey: process.env.REACT_APP_WRAPPER_SECRET_KEY,
          };
          getAnomalyDetection(anomalyDetectionData ,key.id);
        }
        else{
          setLoadingStates((prev:any) => ({ ...prev, [key.id]: false })); 
        }
      })
    };
    reader.readAsDataURL(file);
  };

  const getValueForKey=(val:any)=>{
    let finalVal = <span>{val}</span>;
      if (val === 1) {
        finalVal = <span className="text-[#50b800]">Accepted</span>;
      } else if (val === 2) {
        finalVal = <span className="text-[#ea0000]">Rejected</span>;
      }else {
        finalVal = <span>-</span>;
      }
    return finalVal
  }

  const formatViewData = (view: any) => {
    return {
      status: view.status !== undefined ? view.status : 0,
      damage: view.damage || '-',
      part: view.part || '-',
      severity: view.severity || '-',
      size: view.size || '-', 
      description: view.description || '-',
      solution: view.solution || '-',
      'Repair Cost': view.price ? `${view.price} INR` : 0,
    };
  };

  const handleStatusUpdate = (questionId:any, annotatedId: any, statusId:any) => {
   const overlayItem = answerDetails.find((item:any) => item.id === questionId);
    if (overlayItem) {
      const updatedDamageDetails = {
        ...overlayItem,
        annotatedDetails: overlayItem.annotatedDetails?.map((detail: any) => {
          if (detail.id === annotatedId) {
            return {
              ...detail,
              viewDetails: {
                ...detail.viewDetails,
                status: statusId,
              },
            };
          }
          return detail;
        }),
      };
      setSelectedInspection(updatedDamageDetails);
      setAnswerDetails((prevDetails:any) =>
        prevDetails.map((detail:any) =>
          detail.id === questionId
            ? {
                ...detail,
                ...updatedDamageDetails,
              }
            : detail
        )
      );
    }
  };
  return (
    <div className="flex w-full">
      {/* Slider */}
      <div className="w-full flex flex-wrap gap-[3%]">
        {answerDetails.map((key: any, i: number) => (
          <div key={key.id} className="w-[17%] mb-[5rem]">
            <div className={`relative w-full h-[120px] bg-gray-200 rounded-lg shadow-lg overflow-hidden flex flex-col ${key?.annotatedDetails && key.annotatedDetails.length > 0 ? 'border-4 border-rose-500' : ''}`}>
              {key.url ? (
                <>
                  <img className="w-full h-full object-fill" src={key.url} alt={key.name} />
                  <button onClick={() => openModal(key)} className="w-[10%] h-auto cursor-pointer absolute bottom-2 right-2 z-[1]">
                    <img src={viewIcon} alt={key.name} />
                  </button>
                </>
              ) : (
                <div className="w-auto h-full">
                  {key.overlayUrl ? (
                    <>
                      <label>
                        <img className="w-full h-full object-fill" src={key.overlayUrl || undefined} alt={key.name} />
                        {uploadedPdfUrl && (
                          <input type="file" accept="image/*" className="hidden" onChange={(e) => handleImageUpload(e, key)} />
                        )}
                      </label>
                    </>
                  ) : (
                    <label>
                      <img className="w-full h-full cursor-pointer" src={require("../../../assets/images/unidentified.png")} alt={key.name} />
                      {uploadedPdfUrl && (
                        <input type="file" accept="image/*" className="hidden" onChange={(e) => handleImageUpload(e, key)} />
                      )}
                    </label>
                  )}
                </div>
              )}

             {loadingStates[key.id] && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col gap-[1rem] items-center justify-center z-11">
                <div className="ai_progress_loader"></div>
                <p className="text-[1.3rem] font-bold absolute bottom-1 text-white">AI is in progress</p>
              </div>
            )}
            </div>
            <h3 className="text-[1.2rem] font-bold mt-2 text-gray-800 text-ellipsis whitespace-nowrap dark:text-io-white">
              {key.name}
            </h3>
            <h6 className="text-[1.2rem] text-gray-600 text-ellipsis whitespace-wrap dark:text-io-white">
              {key.additionalParts && Array.isArray(key.additionalParts) 
              ? key.additionalParts.map((part: string) => part.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")).join(", ")
              : ""
              }
            </h6>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="relative w-[70%] h-[85dvh] bg-white dark:bg-gray-900 rounded-[2.2rem] p-8 shadow-lg max-md:w-[90%]">
              <h1 className="text-3xl font-semibold text-center mb-4 dark:text-io-white">
                Inspection Details for {selectedInspection?.name}
              </h1>
              <button
                className="absolute top-8 right-8 text-gray-600 hover:text-black text-3xl dark:text-io-white"
                onClick={closeModal}
                aria-label="Close modal"
              >
                ✕
              </button>

              <div className="flex flex-col h-[90%] overflow-hidden">
                <div className="flex flex-col md:flex-row h-full">
                  <div className="w-full md:w-[60%] sticky h-full top-4 dark:bg-gray-900 p-3">
                    <ImageEditorNew imageSource={selectedInspection} />
                  </div>

                  <div className="w-full md:w-[40%] h-full ml-auto">
                    <div className="p-6 space-y-4 h-full" >
                      {!isOpenModalForm ?
                        <div className="w-full h-full px-6 overflow-y-auto custom-scrollbar">
                          {selectedInspection?.annotatedDetails && selectedInspection?.annotatedDetails?.length > 0 ? (
                            selectedInspection?.annotatedDetails?.map((finding: any, index: any) => (
                              <div
                                key={finding.id}
                                style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                                className="mt-4 p-8 bg-transparent rounded-lg border border-gray-100"
                              >
                                <div className="flex justify-between">
                                  <div className="w-full">
                                    <div key="status" className="flex w-full mb-4">
                                      <p className="font-bold text-[1.4rem] w-[30%] pr-4">Status:</p>
                                      <p className="text-[1.4rem] w-[70%]" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
                                      {getValueForKey(finding.viewDetails.status)}
                                      </p>
                                    </div>
                                    {defaultInputDetails?.elements?.map((element:any) => {
                                      const key = element.labelKey;
                                      const value = finding.viewDetails[key];
                                      return (
                                        <div key={element.id} className="flex w-full mb-4">
                                          <p className="font-bold text-[1.4rem] w-[30%] pr-4">
                                            {element.name}:
                                          </p>
                                          {Array.isArray(value) ? (
                                            // If value is an array of objects, join their 'value' fields with commas
                                            <p className="text-[1.4rem] w-[70%]" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
                                              {value.map((item) => item.value).join(", ")}
                                            </p>
                                          ) : typeof value === 'object' && value !== null ? (
                                            <pre className="w-full text-[1.4rem] break-words">
                                              {JSON.stringify(value, null, 2)}
                                            </pre>
                                          ) : (
                                            <p className="text-[1.4rem] w-[70%]" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
                                              {value !== undefined && value !== null ? (
                                                <span>{value}</span>
                                              ) : (
                                                <span>Unknown</span>
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                {/* {(finding.viewDetails.status === 0 || !('status' in finding.viewDetails)) && (
                                <div className="flex items-center mt-6 pl-[25%] gap-4">
                                  <button type="button" onClick={() => handleStatusUpdate(selectedInspection?.id,finding.id,1)} className="uppercase py-2 px-6 text-[1.3rem] text-white rounded-md bg-[#50b800]">Accept</button>
                                  <button type="button" onClick={() => handleStatusUpdate(selectedInspection?.id,finding.id,2)} className="uppercase py-2 px-6 text-[1.3rem] text-white rounded-md bg-[#ea0000]">Reject</button>
                                </div>
                                )} */}
                              </div>
                            ))
                          ) : (
                            <div
                              style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                              className="mt-4 p-8 bg-transparent rounded-lg border border-gray-100"
                            >
                              <p className="text-[1.4rem] text-center font-bold text-gray-500">No damage found</p>
                            </div>
                          )}
                        </div>
                        :
                        <div className="damageDetail_form h-full">
                          <form className="w-full h-full">
                            <p className="text-[1.2rem] italic mb-[1rem] dark:text-io-white">Damage 1</p>
                            <div className="input_sections mb-[2%] h-[89%] overflow-y-auto custom-scrollbar">
                              <div className="mb-[3rem] mt-[1rem]">
                                <InputField
                                  labelName={"Part"}
                                  type="text"
                                  id="partName"
                                />
                              </div>
                              <div className="mb-[2.5rem]">
                                <InputField
                                  labelName={"Damage Type"}
                                  type="text"
                                  id="damageType"
                                />
                              </div>
                              <div className="mb-[2.5rem]">
                                <InputField
                                  labelName={"Intensity"}
                                  type="text"
                                  id="intensity"
                                />
                              </div>
                              <div className="mb-[2.5rem]">
                                <InputField
                                  labelName={"Size"}
                                  type="number"
                                  id="sizeInput"
                                />
                              </div>
                              <div className="mb-[2.5rem]">
                                <TextAreaField
                                  labelName={"Description"}
                                  id="description"
                                />
                              </div>
                              <div className="mb-[2.5rem]">
                                <TextAreaField
                                  labelName={"Solution"}
                                  id="solution"
                                />
                              </div>
                            </div>
                            <div className="flex items-center gap-4">
                              <button type="submit" className="uppercase py-2 px-6 text-[1.3rem] text-white rounded-md bg-[#50b800]">Accept</button>
                              <button type="button" className="uppercase py-2 px-6 text-[1.3rem] text-white rounded-md bg-[#ea0000]">Reject</button>
                            </div>
                          </form>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </>
      )}
    </div>
  );
};

export default DamageImageDetails;
