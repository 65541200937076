import React from "react";
import { TextBtn } from "../../form/text-btn";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import defaultImage from "../../../assets/images/default.png";
import overRidden from "../../../assets/images/overRidden.png";
import IconBtn from "../../form/icon-btn";
import { ImportIcon } from "../../svg";
function PageHeader({
  title,
  icon,
  hasSaveBtn,
  handleSubmit,
  isLoading,
  allGetStarted,
  isGetStartedAvailable,
  overridden,
  handleExportClick,
  downloadLoading,
}: any) {
  const { t } = useTranslation();
  const handleImageError = (event: any) => {
    event.target.src = defaultImage;
  };
  return (
    <div className="w-full h-[12rem] sticky top-0 z-10 bg-io-white dark:bg-io-black-15 flex justify-center items-center">
      <div className="w-full h-full flex justify-between items-center gap-10 border-b border-io-gray-c8 dark:border-io-black-48">
        <div className="flex-1 h-auto z-[12] max-md:w-[80%]">
          <div className="w-auto flex justify-start items-center gap-6 ">
            {isLoading ? (
              <Skeleton width={60} height={60} borderRadius={50} />
            ) : (
              allGetStarted?.[0]?.icon && (
                <div className="w-[7%] h-auto">
                  <img
                    src={allGetStarted[0].icon}
                    alt="PreviewImage"
                    onError={(e) => handleImageError(e)}
                    className="w-full object-scale-down"
                  />
                </div>
              )
            )}
            <div className="flex-1">
              {isLoading ? (
                <Skeleton width={500} height={40} borderRadius={3} />
              ) : (
                <div className="w-full flex justify-start items-center gap-4">
                  <h1 className="text-[2.8rem] text-io-black font-inter font-normal dark:text-io-white">
                    {title}
                  </h1>
                  <div
                    className={`w-auto inline-block rounded-[0.2rem] px-2 py-1 bg-opacity-10 
                      bg-io-green text-io-green}`}
                    style={{ backgroundColor: allGetStarted?.[0]?.fillColor }}
                  >
                    {" "}
                    <div className="flex items-center">
                      <p
                        className="text-[1.6rem] font-inter font-normal text-inherit"
                        style={{ color: allGetStarted?.[0]?.textColor }}
                      >
                        {allGetStarted?.[0]?.statusText || ""}
                      </p>{" "}
                      <div className="h-auto w-[1rem] block">
                        {overridden == 1 && (
                          <img
                            className="h-[1.5rem] object-cover ml-2"
                            src={overRidden}
                            alt="PreviewImage"
                            onError={(e) => handleImageError(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="w-full h-[auto] flex justify-start items-start divide-x divide-io-gray-c8 flex-wrap mb-10">
                {isLoading ? (
                  <Skeleton width={500} height={25} borderRadius={3} />
                ) : (
                  <>
                    {isGetStartedAvailable === 1 &&
                      allGetStarted.slice(1).map((item: any, index: any) => {
                        if (!item.labelValue) {
                          return null;
                        }
                        return (
                          <React.Fragment key={index}>
                            <p className="text-[1.4rem] font-inter font-medium text-io-gray-66 leading-none px-4 dark:text-io-black-d1">
                              {item.labelValue}
                            </p>
                          </React.Fragment>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {hasSaveBtn && (
          <div className="w-auto h-auto max-md:w-[20%]">
            <div className="w-full flex justify-end items-center">
              {downloadLoading ? (
                <Skeleton width={150} height={40} borderRadius={3} />
              ) : (
                <div>
                  <IconBtn
                    size="w-[2rem]"
                    icon={<ImportIcon />} // Use your export icon here
                    handleClick={() => handleExportClick()} // Handle export on click
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {hasSaveBtn && (
          <div className="w-auto h-auto max-md:w-[20%]">
            <div className="w-full flex justify-end items-center">
              {isLoading ? (
                <Skeleton width={150} height={40} borderRadius={3} />
              ) : (
                <TextBtn
                  label={t("web.common.save")}
                  btnStyle="fill-primary"
                  btnType="button"
                  handleClick={handleSubmit}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default PageHeader;
