import ThemeToggleButton from "../../form/theme-toggle-btn";
import image from "../../../assets/images/user.png";
import { useEffect, useState } from "react";
import defaultImage from "../../../assets/images/default.png";
interface HeaderProps {}
function Header({}: HeaderProps) {
  const [imageUrl, setImage] = useState(image);

  useEffect(() => {
    setTimeout(() => {
      setImage(sessionStorage.getItem("image") || image);
    }, 5000);
  }, []);
  const handleImageError = (event: any) => {
    event.target.src = defaultImage;
  };
  return (
    <div className="w-full h-[6rem] bg-io-primary z-10 sticky top-0">
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-full h-auto mx-8">
          <div className="w-full h-auto flex justify-between items-center">
            {/* <h1 className="text-[1.8rem] font-inter font-normal text-io-white uppercase">
              Inspection one
            </h1> */}
            <div className="w-[20.4rem] h-[6.4rem] overflow-hidden rounded-full flex justify-center items-center">
              <img
                src={
                  "https://inspectiononeprodstorage.blob.core.windows.net/logo/inspection-one.png"
                }
                alt="InspectionOne"
                onError={(e) => handleImageError(e)}
              />
            </div>
            <ul className="w-auto h-auto flex justify-end items-center gap-[3rem]">
              <li className="w-auto h-auto ">
                <ThemeToggleButton />
              </li>
              <li className="w-[4rem] h-[4rem] rounded-full overflow-hidden relative ">
                <img
                  className="object-cover object-center"
                  // src={require("../../../assets/images/profile.png")}
                  src={imageUrl}
                  alt="Profile"
                  onError={(e) => handleImageError(e)}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
