import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { useEffect, useMemo, useState } from "react";
import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import deteleIcon from "../../../assets/images/editorDeteleIcon.svg";
import { TextBtn } from "../../form/text-btn";
import { InputFieldWithoutForm, TextAreaField } from "../../form/input-fields";
import { ToggleButtonWithoutForm } from "../../form/toggle-btn";
import { SelectInputWithoutForm } from "../../form/select-input";
import { useTranslation } from "react-i18next";

interface ImageEditorProps {
  imageSource: any;
}

declare module "fabric" {
  interface Canvas {
    __eventListeners: any;
  }
}
declare module "fabric" {
  namespace fabric {
    interface Object {
      uniqueId?: any;
      isDeleted?: any;
    }
  }
}

function ImageEditor({ imageSource }: ImageEditorProps) {
  const generateGuid = () => uuidv4();
  const { editor, onReady, selectedObjects } = useFabricJSEditor();
  const [selectedImage, setSelectedImage] = useState<boolean>(false);
  const [strokeLevel, setStrokeLevel] = useState<Number>(10);
  const [cropImage, setCropImage] = useState<boolean>(false);

  const renderImage = (base64URL: string) => {
    console.log(base64URL)
    if (!editor || !fabric) {
      console.log("renderImage: editor or fabric is not defined");
      return;
    }
    fabric.Image.fromURL(base64URL, function (img) {
      editor.canvas.setBackgroundImage(
        img,
        editor.canvas.renderAll.bind(editor.canvas)
      );
      img.set({
        selectable: true,  // Don't select background image
        hasControls: true, // Disable controls on the background
        lockMovementX: false, // Allow horizontal movement
        lockMovementY: false, // Allow vertical movement
        scaleX: Number(editor.canvas.width) / Number(img.width),  // Adjust to fit the canvas size
        scaleY: Number(editor.canvas.height) / Number(img.height) // Adjust to fit the canvas size
      });
  
      // Optional: set image position to ensure it fits the canvas
      img.set({
        left: 0,
        top: 0,
        originX: 'left',
        originY: 'top'
      });
      if (Number(img.width) >= 100 || Number(img.height) >= 100) {
        const zoomLevel = Math.min(
          Number(editor.canvas.width) / Number(img.width),
          Number(editor.canvas.height) / Number(img.height)
        );
        const centerX = Number(editor.canvas.width) / 20;
        const centerY = Number(editor.canvas.height) / 20;
        editor.canvas.zoomToPoint({ x: centerX, y: centerY }, zoomLevel);
      } else {
        editor.canvas.zoomToPoint({ x: 1, y: 1 }, 1.4);
      }
      editor.canvas.renderAll();
    });
  };

  useEffect(() => {
    if (!editor || !fabric) {
      console.log("renderImage: editor or fabric is not defined");
      return;
    }
    if (imageSource) {
      setSelectedImage(true);
      renderImage(imageSource.url);
    } else {
      setSelectedImage(false);
    }
  }, [imageSource, editor, fabric]);



  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    if (
      !editor ||
      !editor.canvas.width ||
      !editor.canvas.height ||
      !editor.canvas.backgroundImage
    ) {
      return;
    }
    editor.canvas.freeDrawingBrush.width = Number(strokeLevel);
    const obj: any = editor.canvas.getActiveObjects();
    if (obj) obj[0]?.set({ strokeWidth: strokeLevel });
    editor.canvas.isDrawingMode = false;
  }, [editor, strokeLevel]);

  function handleMouseWheel(event: any) {
    if (!editor || !fabric) {
      return;
    }
  
    let delta = event.e.deltaY;
    let zoom = editor.canvas.getZoom();
    zoom *= 0.999 ** delta;
  
    // Constrain zoom levels
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
  
    editor.canvas.zoomToPoint({ x: event.e.offsetX, y: event.e.offsetY }, zoom);
  
    // Update background image scaling with zoom
    const bgImage = editor.canvas.backgroundImage;
    if (bgImage) {
      editor.canvas.renderAll();
    }
  
    event.e.preventDefault();
    event.e.stopPropagation();
  }
  
  function handleMouseDown(this: any, opt: any) {
    var evt = opt.e;
    this.isDragging = true;
    this.selection = false;
    this.lastPosX = evt.clientX;
    this.lastPosY = evt.clientY;
  }
  
  function handleMouseMove(this: any, opt: any) {
    if (this.isDragging) {
      var e = opt.e;
      var vpt = this.viewportTransform;
      vpt[4] += e.clientX - this.lastPosX;
      vpt[5] += e.clientY - this.lastPosY;
      this.requestRenderAll();
      this.lastPosX = e.clientX;
      this.lastPosY = e.clientY;
    }
  }
  
  function handleMouseUp(this: any, opt: any) {
    this.setViewportTransform(this.viewportTransform);
    this.isDragging = false;
    this.selection = true;
  }
  
  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
  
    editor.canvas.on("mouse:wheel", handleMouseWheel);
    if (cropImage) {
      editor.canvas.on("mouse:down", handleMouseDown);
      editor.canvas.on("mouse:move", handleMouseMove);
      editor.canvas.on("mouse:up", handleMouseUp);
      editor.canvas.isDrawingMode = false;
    }
    editor.canvas.renderAll();
    return () => {
      editor.canvas.off("mouse:wheel", handleMouseWheel);
      editor.canvas.off("mouse:down", handleMouseDown);
      editor.canvas.off("mouse:move", handleMouseMove);
      editor.canvas.off("mouse:up", handleMouseUp);
    };
  }, [editor, cropImage]);
  


  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
  
    const createFabricObjects = (annotationDetails:any) => {
      if (!fabric || !annotationDetails) {
        return [];
      }
      const objects = annotationDetails.map((annotation:any) => {
        let fabricObject;
  
        switch (annotation.type) {
          case 1: // Rectangle
            if (Array.isArray(annotation.coordinates) && annotation.coordinates.length === 2) {
              const [start, end] = annotation.coordinates;
              fabricObject = new fabric.Rect({
                left: start.x,
                top: start.y,
                width: end.x - start.x,
                height: end.y - start.y,
                fill: "transparent",
                stroke: annotation.hexaColor,
                strokeWidth: 2,
                selectable: false
              });
            }
            break;
  
          case 2: // Circle
            if (Array.isArray(annotation.coordinates) && annotation.coordinates.length === 2) {
              const [center, edge] = annotation.coordinates;
              const radius = Math.sqrt(
                Math.pow(edge.x - center.x, 2) + Math.pow(edge.y - center.y, 2)
              );
              fabricObject = new fabric.Circle({
                left: center.x - radius,
                top: center.y - radius,
                radius,
                fill:"transparent",
                stroke: annotation.hexaColor,
                strokeWidth: 2,
                selectable: false
              });
            }
            break;
  
          case 3: // Free flow (Path)
            if (Array.isArray(annotation.coordinates)) {
              const pathData = annotation.coordinates.map((point:any, index:any) =>
                index === 0 ? `M ${point.x} ${point.y}` : `L ${point.x} ${point.y}`
              ).join(" ");
              fabricObject = new fabric.Path(pathData, {
                fill: "transparent",
                stroke: annotation.hexaColor,
                strokeWidth: 2,
                selectable: false,
              });
            }
            break;
  
          default:
            console.warn(`Unsupported annotation type: ${annotation.type}`);
        }
        return fabricObject;
      });
      // Filter out null or undefined objects
      return objects.filter((obj:any) => obj !== null && obj !== undefined);
    };
  
    const annotatedDetails = imageSource?.annotatedDetails;
    const fabricObjects = createFabricObjects(annotatedDetails);
    // Log the fabricObjects array for debugging
    console.log('Fabric objects:', fabricObjects);
  
    // Add the objects to the canvas
    if (editor && editor.canvas && fabricObjects.length > 0) {
      editor.canvas.clear();
      editor.canvas.add(...fabricObjects);
    }
  }, [editor, fabric]);
  

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    fabric.Object.prototype.set({
      transparentCorners: false,
      cornerColor: "#0078d4",
      cornerStrokeColor: "white",
      borderColor: "#0078d4",
      cornerSize: 12,
      borderOpacityWhenMoving: 0.5,
      padding: 10,
      cornerStyle: "circle",
      borderDashArray: [5, 5],
      uniqueId: generateGuid(),
      isDeleted: 0,
    });
  }, [editor]);

 
  useEffect(() => {
    const img = new Image();
    img.src = deteleIcon;
    function deleteObject(eventData: MouseEvent, transform: any): boolean {
      const target = transform.target;
      const canvas = target.canvas;
      if (canvas) {
        canvas.remove(target);
        canvas.requestRenderAll();
        return true;
      }
      return false;
    }
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 0,
      offsetX: 20,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: (ctx, left, top, styleOverride, fabricObject: any) => {
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -20 / 2, -20 / 2, 20, 20);
        ctx.restore();
      },
    });
  }, [editor]);

  return (
    <div className="w-full h-auto">
      <div className="w-full h-[50rem] relative bg-[#F4FAFF] dark:bg-io-black-1a">
        <div className="w-full h-full rounded-[8px] overflow-hidden">
          <FabricJSCanvas className="w-full h-full" onReady={onReady} />
        </div>
      </div>
    </div>
  );
}
export default ImageEditor;
