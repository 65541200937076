import * as CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

const generateGuid = (): string => {
  return uuidv4();
};

const generateCheckSumValue = (dataToHash: string): string => {
  const modifiedString = dataToHash.substring(3, dataToHash.length - 3).trim();
  const oddIndexCharacters = getCharactersAtOddIndices(modifiedString);
  const checkSumKey = CryptoJS.HmacSHA256(oddIndexCharacters, process.env.REACT_APP_SECURE_KEY||"").toString(CryptoJS.enc.Hex);
  return checkSumKey;
};

const getCharactersAtOddIndices = (str: string): string => {
  const characters = str.split("");
  const oddIndexCharacters = characters.filter((_, index) => (index + 1) % 2 !== 0);
  return oddIndexCharacters.join("");
};

const apiKey = process.env.REACT_APP_KEY ||"";
 

interface HeaderValues {
  getHeaders: (fileType: string, siteUniqueId: string) => {
    "Content-Type": string;
    "X-API-Key": string;
    "X-Random-Key": string;
    "X-CheckSumValue-Key": string;
    "Site-Id": string;
    "File-Type": string;
  };
}

const headerValues: HeaderValues = {
  getHeaders: (fileType: string, siteUniqueId: string) => {
    const generatedGuid = generateGuid();
    return {
      "Content-Type": "application/json",
      "X-API-Key": apiKey,
      "X-Random-Key": generatedGuid,
      "X-CheckSumValue-Key": generateCheckSumValue(generatedGuid),
      "Site-Id": siteUniqueId,
      "File-Type": fileType,
      "Access-Control-Allow-Origin":"*"
    };
  },
};

const updateCommonDetails = (siteUniqueId:any,languageCode:any) => {
  
  return {
    lat: "",
    long: "",
    sourceId: "1",
    sourceDetails: "Web",
    languageToken: languageCode||"en",
    tenantId: "",
    siteId: siteUniqueId,
    siteUserId: "",
    "Master-Access-Id": "",
  };
};

const requestDetails = (serviceDetails: any,siteUniqueId:any,languageCode:any) => ({
  commonDetails: updateCommonDetails(siteUniqueId,languageCode),
  serviceDetails,
});

const makeRequest = async (url: string, method: string, data: any, fileType = "", masterAccessId = "0", siteUniqueId:any) => {
  try { 
    const headers = headerValues.getHeaders(fileType,siteUniqueId);
    let body;
    if (data.serviceDetails instanceof ArrayBuffer) { 
      body =  data.serviceDetails ;
    } else {
      body = JSON.stringify(data);
    } 
    const response = await fetch(url, {
      method,
      headers,
      body
    });

    if (response.status === 200) {
      const responseData = await response.json();
      if (responseData.responseCode.toString() === "200" || responseData.responseCode.toString() === "102") {
        return responseData.responseResult === 'AuthToken has expired.'
          ? { success: false, message: responseData.responseResult }
          : { success: true, data: responseData.serviceDetails ? responseData.serviceDetails : responseData.responseResult  };
      } else {
        const message = responseData.responseMessage.toString().trim().replace(/^"|"$/g, '') ;
        return { success: false, message: message };
      }
    } else {
      return { success: false, message: "Something went wrong. Please contact the administrator." };
    }
  } catch (error) {
    return { success: false, message: "Something went wrong. Please contact the administrator." };
  }
};

const createService = () => ({ 
  handleRequest: async (endpointKey: string, data: any, method = "POST", fileType = "",siteUniqueId = "",languageCode = "", masterAccessId = "0") => {
    let requestData;
    if (endpointKey.toLowerCase().includes("anomalydetection")) {
      requestData = data;
    }else {
      requestData = requestDetails(data,siteUniqueId,languageCode);
    }
    return makeRequest(endpointKey, method, requestData, fileType, masterAccessId,siteUniqueId);
  }
});


export { headerValues, createService, updateCommonDetails, requestDetails, makeRequest };
